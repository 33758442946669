import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useInterval } from 'react-use'
import { Book } from '../../store/books/types'
import { getDropdownState } from '../../store/upload/selectors'
import styles from './uploadDropDownMenu.module.scss'
import ComponentFooter from '../ComponentFooter/ComponentFooter'

interface Props {
  gridIndex: number
  cancel: () => void
  setBook: (val: number) => void
  books: Book[] | undefined
  setPermission: (val: string) => void
  permissions: string[]
  processing: boolean
  disableSaving?: boolean
  dropdownState: string
  copy: () => void
  submit: () => void
}

const processingLetters = Array.from('Processing...')

const UploadMenuFooter: React.FC<Props> = ({
  gridIndex,
  cancel,
  setBook,
  books,
  setPermission,
  permissions,
  processing,
  disableSaving,
  dropdownState,
  copy,
  submit
}) => {
  const isNewWatchlist =
    useSelector(getDropdownState)(gridIndex) === 'newWatchlist'

  const [processingCounter, setProcessingCounter] = useState(0)
  useInterval(
    () => setProcessingCounter((counter) => ++counter),
    processing ? 150 : null
  )
  const handleNumberChange =
    (setValue: (val: number) => void) =>
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setValue(Number(e.target.value))
    }

  const buttonLabel = useCallback(() => {
    if (!processing) return 'Apply'
    return processingLetters.map((letter, i) => {
      const cssClass =
        processingCounter % processingLetters.length === i
          ? styles.processingHighlight
          : ''
      return (
        <span key={i} className={cssClass}>
          {letter}
        </span>
      )
    })
  }, [processing, processingCounter, processingLetters])
  const handleChange =
    (setValue: (val: string) => void) =>
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setValue(e.target.value)
    }

  return (
    <>
      {isNewWatchlist && (
        <div className={styles.dropDownLabels}>
          <div>
            <label>Book:</label>
            <select onChange={handleNumberChange(setBook)}>
              {books?.map((book, i) => (
                <option key={i} value={book.id}>
                  {book.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Permissions:</label>
            <select onChange={handleChange(setPermission)}>
              {permissions.map((p, i) => (
                <option key={i} value={p}>
                  {p}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <ComponentFooter
        onCancelClick={cancel}
        cancelText="Close"
        submitText={dropdownState === 'invalidUpload' ? 'copy' : buttonLabel()}
        disableSubmit={processing || disableSaving}
        onApplyClick={dropdownState === 'invalidUpload' ? copy : submit}
      />
    </>
  )
}

export default UploadMenuFooter
