import React, { FC } from 'react'
import bondsLogoDarkTheme from '../../../assets/images/logo_white_blue.png'
import bondsLogoLightTheme from '../../../assets/images/bonds_logo_black.png'
import styles from './modalFooter.module.scss'
import { useSelector } from 'react-redux'
import { getUseLightTheme } from '../../../store/userPreferences/selectors'

export interface Props {
  title?: string
  content?: string | JSX.Element | undefined
  customStyles?: any
  timer?: any
  icon?: React.ReactNode
  buttonComponent?: React.ReactNode
  dataTestId: string
}

const ModalFooter: FC<Props> = (props) => {
  const {
    title,
    content,
    customStyles,
    timer,
    buttonComponent,
    icon,
    dataTestId
  } = props
  const useLightTheme = useSelector(getUseLightTheme)
  const bondsLogoTheme = useLightTheme
    ? bondsLogoLightTheme
    : bondsLogoDarkTheme

  if (!title && !content) {
    // logo needs to be set like this because the last look popup window
    // will display a broken image if set in an img tag
    return (
      <div className={styles.bottom}>
        <div className={styles.buttonsComponents}>{buttonComponent}</div>
        <div className={styles.bottomModalLogo}>
          <img alt="bonds.com" src={bondsLogoTheme} />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.bottom}>
      <div
        data-testid={dataTestId}
        className={`${styles.bottomContent} ${styles[customStyles.content]}`}
      >
        {timer ||
          (icon && (
            <div className={`${styles.bottomIcn} ${styles[customStyles.icon]}`}>
              {timer || icon}
            </div>
          ))}
        <div className={`${styles.bottomText}`}>
          <div className={`${styles.bottomTextTitle}`}>{title}</div>
          <div className={`${styles.bottomTextContent}`}>{content}</div>
        </div>
      </div>
      <div className={styles.buttonsComponents}>{buttonComponent}</div>
      <div className={styles.bottomModalLogo}>
        <img alt="bonds.com" src={bondsLogoTheme} />
      </div>
    </div>
  )
}

export default ModalFooter
