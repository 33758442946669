import { faExternalLinkAlt } from '@awesome.me/kit-5de77b2c02/icons/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import { popoutSecurity } from '../../../store/depthOfMarket/actions'

export const PopoutDepthIcon = ({ securityId }: { securityId: number }) => {
  const { fin, manageWindows } = useOpenFin()
  const dispatch = useDispatch()
  const onPopout = useCallback(() => {
    dispatch(popoutSecurity(securityId))
    fin && manageWindows(`Depth/${securityId}`, 'restore')
  }, [securityId])

  return (
    <span>
      <FontAwesomeIcon
        icon={faExternalLinkAlt}
        onClick={onPopout}
        title="Popout Depth for this Security"
      />
    </span>
  )
}
