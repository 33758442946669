import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import {
  GreaterOrLessThan,
  GreaterOrLessThanStrings
} from '../../../../store/alertFilter/types'
import {
  SeriesTypeFromString,
  StringFromSeriesType
} from '../../../../store/filter/helpers'
import {
  AllRatingValues,
  AllSeries,
  CurrencyValues,
  defaultFilter,
  Filter,
  MaturitiesValues,
  Sectors
} from '../../../../store/filter/types'
import DropdownRatings from '../../../DropdownRatings/DropdownRatings'
import GenericDropdownMenu from '../../../GenericDropdownMenu/GenericDropdownMenu'
import GenericDropdownMenuMulti from '../../../GenericDropdownMenu/GenericDropdownMenuMulti'
import RadioButtonGroup from '../../../RadioButtonGroup/RadioButtonGroup'
import StyledFieldset from '../../../StyledFieldset/StyledFieldset'
import { CouponTypeMap, RatingMap, SecurityTypesMap } from './helpers'

import styles from './AdvancedFilterEditor.module.scss'
import toggleStyles from '../../../RadioButtonGroup/ToggleStyles.module.scss'

type Props = {
  filter?: Filter
  onEditFilter: (filter: Filter) => void
}

const initReducer = (filter: Filter | undefined) => {
  return { ...(filter || defaultFilter), isChanged: false }
}

type FilterWithoutSecurities = Omit<Filter, 'securitiesAndTickers'>

const createActionForProperty = <P extends keyof FilterWithoutSecurities>(
  prop: P,
  value: unknown
) => {
  const payload = value as Filter[P]
  return {
    type: `update_${prop}` as const,
    payload
  } as const
}

type UpdateFilterActions = UpdateActions<FilterWithoutSecurities>

type InitAction = {
  type: 'init_filter'
  payload?: Filter
}

type EditFilterState = Filter & { isChanged: boolean }

type FilterArrayProps = KeysWithValsOfType<FilterWithoutSecurities, any[]>
type FilterRangeProps = KeysWithValsOfType<
  FilterWithoutSecurities,
  GreaterOrLessThan
>

type FilterNumberProps = 'price' | 'yield' | 'bidAskDiff'

const advancedFilterEditorReducer = (
  filter: EditFilterState,
  action: UpdateFilterActions | InitAction
) => {
  switch (action.type) {
    case 'init_filter':
      return initReducer(action.payload)
    case 'update_bidAskDiff':
      return { ...filter, bidAskDiff: action.payload, isChanged: true }
    case 'update_bidAskDiffGreaterLessThan':
      return {
        ...filter,
        bidAskDiffGreaterLessThan: action.payload,
        isChanged: true
      }
    case 'update_bidAskDiffType':
      return { ...filter, bidAskDiffType: action.payload, isChanged: true }
    case 'update_countries':
      return { ...filter, countries: [...action.payload], isChanged: true }
    case 'update_couponTypes':
      return { ...filter, couponTypes: [...action.payload], isChanged: true }
    case 'update_currencies':
      return { ...filter, currencies: [...action.payload], isChanged: true }
    case 'update_maturities':
      return { ...filter, maturities: [...action.payload], isChanged: true }
    case 'update_price':
      return { ...filter, price: action.payload, isChanged: true }
    case 'update_priceGreaterLessThan':
      return {
        ...filter,
        priceGreaterLessThan: action.payload,
        isChanged: true
      }
    case 'update_ratings':
      return { ...filter, ratings: [...action.payload], isChanged: true }
    case 'update_sectors':
      return { ...filter, sectors: [...action.payload], isChanged: true }
    case 'update_securityTypes':
      return { ...filter, securityTypes: [...action.payload], isChanged: true }
    case 'update_series':
      return { ...filter, series: action.payload, isChanged: true }
    case 'update_yield':
      return { ...filter, yield: action.payload, isChanged: true }
    case 'update_yieldGreaterLessThan':
      return {
        ...filter,
        yieldGreaterLessThan: action.payload,
        isChanged: true
      }
    default:
      return filter
  }
}

const mapValueToItem = (value: string) => value.toString()

const AdvancedFilterEditor = ({ filter, onEditFilter }: Props) => {
  /*  Unfinished range controls not needed for Day 1 of admin feature, so hiding
      until needed here or back in bondslist
   */
  const [showRanges] = useState()
  const [editingFilter, dispatch] = useReducer(
    advancedFilterEditorReducer,
    initReducer(filter)
  )
  useEffect(() => {
    const { isChanged, ...newFilter } = editingFilter
    if (isChanged) onEditFilter(newFilter)
  }, [editingFilter])

  const createGenericDropdownForField = useCallback(
    (
      fieldName: FilterArrayProps,
      label: string,
      allValues: string[] | Record<string, string>
    ) => {
      const mapFn = Array.isArray(allValues)
        ? mapValueToItem
        : (selection: string) => allValues[selection]
      // @ts-ignore
      const selectedItems = editingFilter[fieldName].map(mapFn).sort()
      const allItems = Array.isArray(allValues)
        ? allValues
        : Array.from(Object.values(allValues)).sort()
      const classNames =
        selectedItems.length > 0 && selectedItems.length < allItems.length
          ? styles.hasSelections
          : ''
      return (
        <GenericDropdownMenuMulti
          placeholder={label}
          selectedItems={selectedItems}
          setSelectedItems={(items) => {
            const newItems = Array.isArray(allValues)
              ? items
              : items.map(
                  (item) =>
                    Object.entries(allValues).find(
                      ([key, val]) => val === item
                    )?.[0]
                )
            dispatch(
              // @ts-ignore
              createActionForProperty(
                fieldName,
                newItems.filter((item) => !!item)
              )
            )
          }}
          options={allItems}
          selectId={`afe-select-${fieldName}`}
          className={classNames}
          openRight={true}
        />
      )
    },
    [editingFilter]
  )

  // Ratings is a slightly different flavor of multi
  const selectedRatings = useMemo(() => {
    return editingFilter.ratings.map((rating) => AllRatingValues[rating])
  }, [editingFilter.ratings])

  const setSelectedRatings = useCallback((ratings: string[]) => {
    const numberRatings = ratings.map(
      (item) =>
        Object.entries(RatingMap).find(([key, val]) => val === item)?.[0]
    )
    dispatch(
      createActionForProperty(
        'ratings',
        numberRatings.filter((item) => !!item)
      )
    )
  }, [])

  const ratingsOptions = useMemo(() => {
    return Array.from(Object.values(AllRatingValues))
  }, [])

  const ratingsClass =
    selectedRatings.length && selectedRatings.length < ratingsOptions.length
      ? styles.hasSelections
      : ''

  // series is a one-off, not multi
  const seriesValue = StringFromSeriesType(editingFilter.series)
  const setSeriesValue = useCallback((seriesValueAstString: string) => {
    const newVal = SeriesTypeFromString(seriesValueAstString)
    dispatch(createActionForProperty('series', newVal))
  }, [])
  const seriesClass = seriesValue === 'All Series' ? '' : styles.hasSelections

  // ranges
  const rangeOptions = useMemo(() => {
    return GreaterOrLessThanStrings.map((label, value) => {
      return { label, value }
    })
  }, [])

  const createRangeToggle = useCallback(
    <T,>(fieldName: FilterRangeProps) => (
      <RadioButtonGroup
        onSelect={(newVal) => {
          dispatch(createActionForProperty(fieldName, newVal))
        }}
        options={rangeOptions}
        className={toggleStyles.toggle}
        selectedOption={editingFilter[fieldName]}
        testPrefix={`watchlist-${fieldName}-toggle`}
      />
    ),
    [editingFilter]
  )

  const createQtyInput = useCallback(
    (
      fieldName: FilterNumberProps,
      minmax: 'minimum' | 'maximum' = 'minimum'
    ) => {
      const fieldTitle = `Enter ${minmax} ${fieldName} quantity`
      return (
        <input
          title={fieldTitle}
          type="number"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const newVal =
              e.target.valueAsNumber > 0 ? e.target.valueAsNumber : undefined
            dispatch(createActionForProperty(fieldName, newVal))
          }}
        />
      )
    },
    [editingFilter]
  )

  return (
    <div className={styles.afeContainer}>
      <div className={styles['afe-select-values']}>
        {createGenericDropdownForField('sectors', 'Sector', Sectors)}
        {createGenericDropdownForField(
          'maturities',
          'Maturity',
          MaturitiesValues
        )}
        <DropdownRatings
          placeholder="Ratings"
          selectedItems={selectedRatings}
          setSelectedItems={setSelectedRatings}
          options={ratingsOptions}
          selectId="afe-select-ratings"
          className={ratingsClass}
          openRight={true}
        />
        {createGenericDropdownForField(
          'currencies',
          'Currency',
          CurrencyValues
        )}
        {createGenericDropdownForField(
          'securityTypes',
          'Security Type',
          SecurityTypesMap
        )}
        {createGenericDropdownForField(
          'couponTypes',
          'Coupon Type',
          CouponTypeMap
        )}
        <GenericDropdownMenu
          selectedItem={seriesValue}
          setSelectedItem={setSeriesValue}
          options={AllSeries}
          selectId="afe-select-series"
          openRight={true}
          className={seriesClass}
        />
      </div>
      {showRanges && (
        <div className={`${styles['afe-set-ranges']}`}>
          <StyledFieldset legend="Yield" className={styles['range-fieldset']}>
            {createRangeToggle('yieldGreaterLessThan')}
            {createQtyInput(
              'yield',
              editingFilter.yieldGreaterLessThan ===
                GreaterOrLessThan.GreaterThan
                ? 'minimum'
                : 'maximum'
            )}
          </StyledFieldset>
        </div>
      )}
    </div>
  )
}

export default AdvancedFilterEditor
