import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import gridStyles from '../Grid/grid.module.scss'
import {
  clearPassiveOrders,
  fetchPassiveOrders,
  setViewAllPassiveOrdersAction
} from '../../store/passiveOrders/actions'
import { getViewAllPassiveOrders } from '../../store/passiveOrders/selectors'
import Checkbox from '../Checkbox'
import ComponentHeader from '../ComponentHeader/ComponentHeader'
import PassiveOrdersGrid from './Grid/PassiveOrdersGrid'
import styles from './PassiveOrders.module.scss'
import { finHeaderButtons } from '../ComponentHeader/helpers'
import { getIsAdmin } from '../../store/webSettings/selectors'

const PassiveOrders: FC = () => {
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)
  useEffect(() => {
    dispatch(fetchPassiveOrders())
  }, [])

  const viewAll = useSelector(getViewAllPassiveOrders)

  const checkChanged = useCallback(() => {
    dispatch(setViewAllPassiveOrdersAction(!viewAll))
    dispatch(clearPassiveOrders())
  }, [])

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader
        title="My Orders"
        headerButtons={finHeaderButtons('PassiveOrders')}
      />
      {isAdmin && (
        <div className={styles.topMenu}>
          <Checkbox
            checkboxClass={styles.cb}
            locator="ordersAccess"
            checked={viewAll}
            onChange={checkChanged}
            disabled={false}
          >
            Show all orders from users I have access to see
          </Checkbox>
        </div>
      )}

      <PassiveOrdersGrid />
    </div>
  )
}

export default PassiveOrders
