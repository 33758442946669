import dayjs from 'dayjs'
import React from 'react'
import { SecurityStaticData } from '../../../store/securities/reducer'

import styles from './DepthOfMarket.module.scss'
import { PopoutDepthIcon } from './PopoutDepthIcon'

interface Props {
  security: SecurityStaticData
}

const InlineDepthHeader = ({ security }: Props) => {
  const displayDate = dayjs(security.maturityDate).format('MM/YY')
  const identifier = security.cusip || security.isin || ''

  return (
    <div className={styles.inlineHeader}>
      <p>{`${security.issuerSymbol} ${security.coupon}  ${displayDate} | ${identifier}`}</p>
      <PopoutDepthIcon securityId={security.id} />
    </div>
  )
}

export default InlineDepthHeader
