import { ComponentType } from 'react'
import { ConvertUSTPrice, formatTreasuryPrice } from '../../helpers/formatting'
import { OrderEntryModel } from '../../store/depthOfMarket/types'
import { cancelOrder, createOrder } from '../../store/order/actions'
import { Order, OrderType, TobParameters } from '../../store/order/types'
import { errorMessages } from '../../store/order/validation'
import { SecurityOrderData } from '../../store/securities/reducer'
import { StagedOrderInfo } from '../../store/stagedOrders/reducer'

export const showTitle =
  'Size displayed to the market. Can be smaller than Total Size.'

export function formatSpread(spread: number | undefined) {
  if (spread === undefined) return ''
  const sign = spread >= 0 ? '+' : '-'
  return `${sign}${Math.abs(spread)}`
}

export function orderTypeToAggressLabel(type: OrderType) {
  if (type === 'buy') return 'HIT'
  return 'LIFT'
}

const defaultColumnOrders = ['aggress', 'size', 'price', 'spread'] as const
const reversedColumnOrders = [...defaultColumnOrders].reverse()

export type OrderProps = { order: Order }
export type OrderDisplay = ComponentType<OrderProps>
export function depthColumnsByOrderType(
  type: OrderType,
  otherColumns: OrderDisplay[] = []
) {
  if (!otherColumns.length) {
    if (type === 'buy') return defaultColumnOrders
    return reversedColumnOrders
  }
  const allColumns = [...otherColumns, ...defaultColumnOrders]
  if (type === 'buy') return allColumns
  return allColumns.reverse()
}

export function orderTypeToDisplayName(type: OrderType) {
  if (type === 'buy') return 'Bid'
  return 'Offer'
}

export function displayTypeToOrderType(displayType: 'bid' | 'offer') {
  if (displayType === 'offer') return 'sell'
  return 'buy'
}

export function capitalize(word: string) {
  if (!word.length) return word
  return `${word[0].toUpperCase()}${word.slice(1)}`
}

export const getIsTob = (amount: string | TobParameters) => {
  return typeof amount !== 'string' && 'tob' in amount && amount.tob
}
export const createTob = () =>
  ({
    tob: true,
    limitPrice: null,
    floorPrice: null
  } as const)

type Peggable = Pick<
  Order | StagedOrderInfo,
  'tob' | 'isSpreadOrder' | 'spread' | 'price'
>
export function getOrderAmount(order: Peggable, isTreasury: boolean) {
  if (!order.tob?.tob) {
    if (order.isSpreadOrder) {
      return `${order.spread}`
    }
    if (isTreasury && order.price !== undefined) {
      return formatTreasuryPrice(order.price)
    }
    return `${order.price ?? ''}`
  }
  return order.tob
}

type Sizable = Pick<Order | StagedOrderInfo, 'totalSize' | 'size' | 'allOrNone'>

export const getDisplaySize = (order: Sizable) => {
  if (order.allOrNone) {
    if ('totalSize' in order && order.totalSize) {
      if (order.totalSize && order.totalSize !== order.size) {
        if (order.size) return `${order.size}a`
      }
      return ''
    }
    return '' // size will be used to populate totalSize instead
  }
  if (('totalSize' in order && order.totalSize === order.size) || !order.size) {
    return ''
  }
  return order.size || ''
}

type MinSizable = Sizable & Pick<Order | StagedOrderInfo, 'individualMin'>
export function getMinSize(order: MinSizable) {
  if (!order.allOrNone) {
    /*  Fix for individualMin coming back as '0', probably from the server.
        This is easier than tracking down where that happens.
     */
    return parseInt(`${order.individualMin}`, 10) || ''
  }
  return Math.min(order.totalSize || order.size, order.size)
}

export const getTotalSize = (order: Sizable) => {
  if (!order.totalSize) {
    return `${order.size}${order.allOrNone ? 'a' : ''}`
  }
  const shouldUseA =
    order.allOrNone && (!order.size || order.size === order.totalSize)
  return `${order.totalSize}${shouldUseA ? 'a' : ''}`
}

export function createOrderModel(
  order: Order | StagedOrderInfo | undefined,
  type: OrderType,
  isTreasury: boolean,
  defaultSize: number = 0
) {
  if (!order) {
    return {
      type,
      amountType: 'spread',
      totalSize: `${defaultSize || ''}`,
      displaySize: '',
      minSize: '',
      amount: '',
      aon: false,
      isLive: false
    } as const
  }
  const isLive = (order as object).hasOwnProperty('status')
  const selectedBook = isLive ? order.custId : undefined
  const id = 'id' in order ? order.id : undefined
  return {
    type,
    id,
    amountType: order.isSpreadOrder ? 'spread' : 'price',
    totalSize: getTotalSize(order),
    displaySize: `${getDisplaySize(order)}`,
    minSize: `${getMinSize(order)}`,
    amount: getOrderAmount(order, isTreasury),
    aon: order.allOrNone,
    selectedBook,
    isLive
  } as const
}

const errors = {
  buy: {
    ORDER_SIZE_LESS_THAN_MINIMUM: (minimum: number) =>
      errorMessages.GENERAL_ORDER_SIZE_LESS_THAN_SPECIFIED_SIZE(
        /* can't use this fn in validations bc it creates a circular reference */
        orderTypeToDisplayName('buy'),
        minimum
      ),
    SIZE_EXCEEDS_MAX: errorMessages.BID_SIZE_EXCEEDS_MAX,
    INVALID_PRICE_OR_SIZE: errorMessages.INVALID_BID_PRICE_OR_SIZE,
    price: {
      CROSSING_OR_LOCKING: errorMessages.BID_PRICE_GREATER_THAN_OFFER_PRICE,
      REVERSED_TOB_FIELDS:
        errorMessages.TOB_ORDER_LIMIT_PRICE_LOWER_THAN_FLOOR_PRICE
    },
    spread: {
      CROSSING_OR_LOCKING: errorMessages.OFFER_SPREAD_GREATER_THAN_BID_SPREAD,
      REVERSED_TOB_FIELDS:
        errorMessages.TOB_ORDER_LIMIT_SPREAD_GREATER_THAN_FLOOR_SPREAD
    }
  },
  sell: {
    ORDER_SIZE_LESS_THAN_MINIMUM: (minimum: number) =>
      errorMessages.GENERAL_ORDER_SIZE_LESS_THAN_SPECIFIED_SIZE(
        orderTypeToDisplayName('sell'),
        minimum
      ),
    SIZE_EXCEEDS_MAX: errorMessages.OFFER_SIZE_EXCEEDS_MAX,
    INVALID_PRICE_OR_SIZE: errorMessages.INVALID_OFFER_PRICE_OR_SIZE,
    price: {
      CROSSING_OR_LOCKING: errorMessages.OFFER_PRICE_LOWER_THAN_BID_PRICE,
      REVERSED_TOB_FIELDS:
        errorMessages.TOB_ORDER_LIMIT_PRICE_GREATER_THAN_FLOOR_PRICE
    },
    spread: {
      CROSSING_OR_LOCKING: errorMessages.BID_SPREAD_LOWER_THAN_OFFER_SPREAD,
      REVERSED_TOB_FIELDS:
        errorMessages.TOB_ORDER_LIMIT_SPREAD_LOWER_THAN_FLOOR_SPREAD
    }
  }
}

export function validateOrderModel(
  order: OrderEntryModel,
  oppositeAmount: number,
  tradeMin: number = NaN,
  tradeMax: number = 20000
): string | undefined {
  const totalSize = parseFloat(order.totalSize)
  const displaySize = parseFloat(order.displaySize)
  const minSize = Number(order.minSize)
  const typeMessages = errors[order.type]
  const isTob = isTobParam(order.amount)
  const isSpread = order.amountType === 'spread'
  const isBuy = order.type === 'buy'

  // causes a server error, so exit right away
  if (minSize < 0) {
    return errorMessages.NEGATIVE_MIN_SIZE
  }

  // initial TOB check
  if (isTobParam(order.amount)) {
    const tob = order.amount
    if (!tob.floorPrice) {
      return errorMessages.TOB_ORDER_WITHOUT_FLOOR_PRICE
    }
    if (!tob.limitPrice) {
      return errorMessages.TOB_ORDER_WITHOUT_LIMIT_PRICE
    }
    const tobFieldError = typeMessages[order.amountType].REVERSED_TOB_FIELDS
    const tobResult = compareTobFields(
      tob.floorPrice,
      tob.limitPrice,
      isBuy,
      isSpread,
      tobFieldError
    )
    if (tobResult) return tobResult
  }

  // check size
  if (!totalSize || totalSize < 0 || totalSize % 1 !== 0) {
    return typeMessages.INVALID_PRICE_OR_SIZE
  }
  if (totalSize > tradeMax && !displaySize) {
    return typeMessages.SIZE_EXCEEDS_MAX(totalSize, tradeMax)
  }
  if (minSize && minSize > totalSize) {
    return errorMessages.SIZE_LOWER_THAN_INDIVIDUAL_MIN
  }
  if (displaySize) {
    if (displaySize % 1 !== 0) {
      return typeMessages.INVALID_PRICE_OR_SIZE
    }
    if (displaySize > totalSize) {
      return errorMessages.SHOW_SIZE_GREATER_THAN_TOTAL_SIZE
    }
    if (minSize && minSize > displaySize) {
      return errorMessages.MIN_SIZE_GREATER_THAN_SHOW_SIZE
    }
    if (displaySize > tradeMax) {
      return typeMessages.SIZE_EXCEEDS_MAX(displaySize, tradeMax)
    }
  }
  if (!minSize && tradeMin && totalSize < tradeMin) {
    return typeMessages.ORDER_SIZE_LESS_THAN_MINIMUM(tradeMin)
  }
  // check for crossing and locking
  const crossingError = typeMessages[order.amountType].CROSSING_OR_LOCKING
  if (!isTob) {
    const amount = Number(order.amount)
    if (isNaN(amount) || (!isSpread && amount < 0)) {
      return typeMessages.INVALID_PRICE_OR_SIZE
    }
    if (amount && oppositeAmount) {
      const crossingLockingError = compareAmounts(
        amount,
        oppositeAmount,
        isBuy,
        isSpread,
        crossingError
      )
      if (crossingLockingError) return crossingLockingError
    }
  } else {
    const tobCrossingError = compareAmounts(
      (order.amount as TobParameters).floorPrice || NaN,
      oppositeAmount,
      isBuy,
      isSpread,
      crossingError
    )
    if (tobCrossingError) {
      return tobCrossingError
    }
  }
}

export const emptyTob = { tob: false, limitPrice: null, floorPrice: null }
export const isTobParam = (
  value: string | TobParameters
): value is TobParameters => {
  return typeof value !== 'string'
}
export function createActionFromOrderModel(
  order: OrderEntryModel,
  securityId: number
) {
  const isTob = getIsTob(order.amount)
  if (order.amountType === 'price' && !isTob) {
    const actualPrice = Number(order.amount)
    if (order.id && !actualPrice) return cancelOrder(order.id)
    if (!actualPrice) return undefined
  }
  return createOrder(securityId, order)
}

export function compareAmounts<R>(
  myAmount: number,
  otherAmount: number,
  isBuy: boolean,
  isSpread: boolean,
  errorResult: R
) {
  const buyMultiplier = isBuy ? -1 : 1
  const spreadMultiplier = isSpread ? -1 : 1
  const comparison = (myAmount - otherAmount) * buyMultiplier * spreadMultiplier
  if (comparison <= 0) return errorResult
}

export function compareTobFields<R>(
  floorPrice: number,
  limitPrice: number,
  isBuy: boolean,
  isSpread: boolean,
  errorResult: R
) {
  if (floorPrice === limitPrice) return // equal ok for TOB
  return compareAmounts(floorPrice, limitPrice, isBuy, isSpread, errorResult)
}

export const checkPrice = (price: string, isUsGov: boolean) => {
  const priceNum = Number(price)
  if (!isNaN(priceNum) || !isUsGov) return price
  return `${ConvertUSTPrice(price)}`
}
export const getOppositeFromSecurity = (
  security: SecurityOrderData,
  type: OrderType,
  amountType: OrderEntryModel['amountType']
) => {
  const marketOrder = type === 'buy' ? security.bestOffer : security.bestBid
  if (!marketOrder) return NaN
  if (amountType === 'price') return marketOrder.price
  return marketOrder.spread
}

export type EditableOrderEntryField = Concrete<
  Omit<OrderEntryModel, 'id' | 'type' | 'isLive'>
>
