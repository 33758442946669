import React, { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import { managementReportFetch } from '../../../store/admin/managementReports/actions'
import { getManagementReportsForDate } from '../../../store/admin/managementReports/selectors'
import ManagementReportComponent from './ManagementReport'

interface Props {
  date: Date
}

const ManagementReportWrapper: FC<Props> = ({ date }) => {
  const dispatch = useAppDispatch()
  const managementReport = useAppSelector(getManagementReportsForDate)(date)
  useEffect(() => {
    if (managementReport === undefined) {
      dispatch(managementReportFetch(date))
    }
  }, [managementReport])
  return (
    <React.Fragment>
      {managementReport !== undefined && (
        <ManagementReportComponent managementReport={managementReport} />
      )}
    </React.Fragment>
  )
}

export default ManagementReportWrapper
