import { Action } from 'redux'
import { OrderEntryModel } from '../depthOfMarket/types'
import { StagedOrder } from '../stagedOrders/types'
import { Order, OrderType, TobParameters, ValidationResult } from './types'

export interface SetMyOrdersOpenAction extends Action {
  type: 'order.setMyOrdersOpen'
  payload: { gridIndex: number; open: boolean }
}

export const setMyOrdersOpen = (
  gridIndex: number,
  open: boolean
): SetMyOrdersOpenAction => ({
  type: 'order.setMyOrdersOpen',
  payload: { gridIndex, open }
})

export interface UpdateOrderValidationAction extends Action {
  type: 'order.updateValidation'
  payload: ValidationResult
}

export interface UpdateOrdersValidationsAction extends Action {
  type: 'order.updateValidations'
  payload: ValidationResult[]
}

export interface ResetOrdersValidationAction extends Action {
  type: 'order.resetValidations'
}

export interface SubmitOrderAction extends Action {
  type: 'order.submit'
  payload: {
    order: Order
    orderId: string
    size: number
    custId?: number
    transactionId: number
    spotCrossSelection: string
  }
}

export interface CancelOrderAction extends Action {
  type: 'order.cancel'
  payload: { orderId: string; updateStagedOrder: boolean }
}

export interface CancelOrdersAction extends Action {
  type: 'order.cancelOrders'
  payload: {
    orderIds: Array<Order['id']>
    updateStagedOrder: boolean
  }
}

export interface CancelOrderActionFailure extends Action {
  type: 'order.cancelFailure'
  error: true
  payload: { orderId: string; error: Error }
}

export interface SubmitOrderFailureAction extends Action {
  type: 'order.submitFailure'
  error: true
  payload: {
    orderId: string
    transactionId: number
    error: any
  }
}

export interface FetchUserOrdersAction extends Action {
  type: 'order.fetchUserOrders'
}

export interface FetchOperatorOrdersAction extends Action {
  type: 'order.fetchOperatorOrders'
}

export interface UnsubscribeFetchUserOrdersAction extends Action {
  type: 'order.unsubscribeFetchUserOrders'
}

export interface FetchResubmitOrdersAction extends Action {
  type: 'order.fetchResubmitOrders'
}

export interface LoadResubmitOrdersAction extends Action {
  type: 'order.loadResubmitOrders'
  payload: Record<string, Record<number, Record<OrderType, Order>>>
}

export interface AddOrUpdateUserOrdersAction extends Action {
  type: 'order.addOrUpdateUserOrders'
  payload: Order[]
}

export interface AddOrUpdateOperatorOrdersAction extends Action {
  type: 'order.addOrUpdateOperatorOrders'
  payload: Order[]
}

export interface SetOrderErrorAction extends Action {
  type: 'order.setOrderError'
  payload: Order['id']
}

export interface CreateTempOrderAction extends Action {
  type: 'order.createTempOrder'
  payload: {
    transactionId: number
    securityId: number
    type: OrderType
    price: number
    isSpread: boolean
    size: number
    initialOrder?: Order
    allOrNone: boolean
    individualMin: number
    tob: TobParameters
    totalSize?: number
  }
}

export interface CreateTempAggressorOrderAction extends Action {
  type: 'order.createTempAggressorOrder'
  payload: {
    initialOrder: Order
    transactionId: number
  }
}

export const updateOrderValidation = (
  validationResult: ValidationResult
): UpdateOrderValidationAction => ({
  type: 'order.updateValidation',
  payload: validationResult
})

export const updateOrdersValidations = (
  validationResults: ValidationResult[]
): UpdateOrdersValidationsAction => ({
  type: 'order.updateValidations',
  payload: validationResults
})

export const resetOrdersValidation = (): ResetOrdersValidationAction => ({
  type: 'order.resetValidations'
})

export const submitOrder = (
  order: Order,
  orderId: string,
  size: number,
  transactionId: number,
  custId: number,
  spotCrossSelection: string
): SubmitOrderAction => ({
  type: 'order.submit',
  payload: {
    order,
    orderId,
    size,
    transactionId,
    custId,
    spotCrossSelection
  }
})

export const createTempOrder = (
  transactionId: number,
  securityId: number,
  type: OrderType,
  price: number,
  isSpread: boolean,
  size: number,
  allOrNone: boolean,
  individualMin: number,
  tob: TobParameters,
  initialOrder?: Order,
  totalSize?: number
): CreateTempOrderAction => ({
  type: 'order.createTempOrder',
  payload: {
    transactionId,
    securityId,
    type,
    price,
    isSpread,
    size,
    initialOrder,
    allOrNone,
    individualMin,
    tob,
    totalSize
  }
})

export const createTempAggressorOrder = (
  initialOrder: Order,
  transactionId: number
): CreateTempAggressorOrderAction => ({
  type: 'order.createTempAggressorOrder',
  payload: { initialOrder, transactionId }
})

export const cancelOrder = (
  orderId: string,
  updateStagedOrder = true
): CancelOrderAction => ({
  type: 'order.cancel',
  payload: { orderId, updateStagedOrder }
})

export const cancelOrders = (
  orderIds: string[],
  updateStagedOrder = true
): CancelOrdersAction => ({
  type: 'order.cancelOrders',
  payload: { orderIds, updateStagedOrder }
})

export const cancelOrderFailure = (
  orderId: string,
  error: any
): CancelOrderActionFailure => ({
  type: 'order.cancelFailure',
  error: true,
  payload: { orderId, error }
})

export const submitOrderFailure = (
  orderId: string,
  size: number,
  transactionId: number,
  error: any
): SubmitOrderFailureAction => ({
  type: 'order.submitFailure',
  error: true,
  payload: {
    orderId,
    transactionId,
    error
  }
})

export const createOrders = (params: StagedOrder[]) =>
  ({
    type: 'order.createOrders',
    payload: { params }
  } as const)

export type CreateOrdersAction = ReturnType<typeof createOrders>

export const createOrder = (securityId: number, order: OrderEntryModel) => ({
  type: 'order.createOrder',
  payload: {
    securityId,
    order
  } as const
})

export type CreateOrderAction = ReturnType<typeof createOrder>

export const fetchUserOrders = (): FetchUserOrdersAction => ({
  type: 'order.fetchUserOrders'
})

export const fetchOperatorOrders = (): FetchOperatorOrdersAction => ({
  type: 'order.fetchOperatorOrders'
})

export const unsubscribeFetchUserOrders =
  (): UnsubscribeFetchUserOrdersAction => ({
    type: 'order.unsubscribeFetchUserOrders'
  })

export const fetchResubmitOrders = (): FetchResubmitOrdersAction => ({
  type: 'order.fetchResubmitOrders'
})

export const loadResubmitOrders = (
  resubmitOrders: any
): LoadResubmitOrdersAction => ({
  type: 'order.loadResubmitOrders',
  payload: resubmitOrders
})

export const addOrUpdateUserOrders = (
  orders: Order[]
): AddOrUpdateUserOrdersAction => {
  const retval = {
    type: 'order.addOrUpdateUserOrders',
    payload: orders
  }
  return retval as AddOrUpdateUserOrdersAction
}

export const addOrUpdateOperatorOrders = (
  orders: Order[]
): AddOrUpdateOperatorOrdersAction => {
  const retval = {
    type: 'order.addOrUpdateOperatorOrders',
    payload: orders
  }
  return retval as AddOrUpdateOperatorOrdersAction
}

export const setOrderError = (orderId: Order['id']): SetOrderErrorAction => ({
  type: 'order.setOrderError',
  payload: orderId
})

export interface CreateOrderErrorAction {
  type: 'order.createOrderError'
  payload: { err: any; securityId: number; orderType: OrderType; isin?: string }
}

export const handleErr = (
  err: any,
  securityId: number,
  orderType: OrderType,
  isin?: ValidationResult['isin']
): CreateOrderErrorAction => {
  return {
    type: 'order.createOrderError',
    payload: { err, securityId, orderType, isin }
  }
}

export interface CreateOrderErrorBulkAction {
  type: 'order.createOrderBulkError'
  payload: { error: any }
}

export const handleBulkErr = (error: any): CreateOrderErrorBulkAction => {
  return {
    type: 'order.createOrderBulkError',
    payload: { error }
  }
}

export interface MapFakeTransactionIdAction extends Action {
  type: 'order.mapFakeTransactionId'
  payload: {
    fakeTransactionId: number
    transactionId: number
  }
}

export const mapFakeTransactionId = (
  fakeTransactionId: number,
  transactionId: number
): MapFakeTransactionIdAction => ({
  type: 'order.mapFakeTransactionId',
  payload: {
    fakeTransactionId,
    transactionId
  }
})

export interface SetValidationAction extends Action {
  type: 'order.setValidation'
  payload: { securityId: number; orderType: OrderType }
}

export const setValidation = (securityId: number, orderType: OrderType) => ({
  type: 'order.setValidation',
  payload: { securityId, orderType }
})

export interface SetValidationOpenAction extends Action {
  type: 'order.setValidationOpen'
  payload: { validationOpen: boolean }
}

export const setValidationOpen = (
  validationOpen: boolean
): SetValidationOpenAction => ({
  type: 'order.setValidationOpen',
  payload: { validationOpen }
})

export const clearMyOrders = () => ({
  type: 'order.clearMyOrders'
})

export interface ClearMyOrdersAction extends Action {
  type: 'order.clearMyOrders'
}

export type OrderAction =
  | SetMyOrdersOpenAction
  | UpdateOrderValidationAction
  | UpdateOrdersValidationsAction
  | ResetOrdersValidationAction
  | SubmitOrderAction
  | SubmitOrderFailureAction
  | CancelOrderAction
  | CancelOrderActionFailure
  | FetchUserOrdersAction
  | UnsubscribeFetchUserOrdersAction
  | AddOrUpdateUserOrdersAction
  | AddOrUpdateOperatorOrdersAction
  | CreateTempOrderAction
  | CreateTempAggressorOrderAction
  | SetOrderErrorAction
  | MapFakeTransactionIdAction
  | CreateOrderErrorAction
  | CreateOrderErrorBulkAction
  | SetValidationAction
  | SetValidationOpenAction
  | FetchResubmitOrdersAction
  | LoadResubmitOrdersAction
  | ClearMyOrdersAction
