import React, { FC, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import UserEditorModal from '../../../components/UserSelector/UserEditorModal'
import { getSelectedUser } from '../../../store/users/selectors'
import { copyAppStyles } from '../helpers'
import styles from '../popouts.module.scss'

const popupElementId = 'userEditorPopupRoot'
interface Props {
  closeFunc: () => void
}
export const UserEditorPopoutHost = (props: any) => {
  return <div className={styles.windowContainer} id={popupElementId} />
}

const UserEditorPopout: FC<Props> = ({ closeFunc }) => {
  const { fin, createOpenfinWindow } = useOpenFin()
  const selectedUser = useSelector(getSelectedUser)
  const [externalWindow, setExternalWindow] = useState<any>(null)
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    null
  )

  const val = useRef()

  useEffect(() => {
    val.current = externalWindow
  }, [externalWindow])

  const onUnload = () => {
    if (val.current) {
      const w = val.current as any
      w.close(true)
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    return () => {
      if (val.current) {
        const w = val.current as any
        w.close(true)
      }
    }
  }, [])

  useEffect(() => {
    fin.desktop.System.getMousePosition((mousePosition: any) => {
      renderWindow(mousePosition.left, mousePosition.top)
    })
  }, [])

  const renderWindow = (left: number, top: number) => {
    if (externalWindow) {
      externalWindow.setAsForeground()
    } else {
      createOpenfinWindow('UserEditor', left, top, false, true)
        .then((extWindow) => {
          if (extWindow) {
            const webWindow = extWindow.getWebWindow()
            setContainerElement(
              webWindow.document.getElementById(popupElementId)
            )
            copyAppStyles(webWindow)
            extWindow.on('close-requested', (e: any) => {
              extWindow.hide()
            })
          }
          setExternalWindow(extWindow)

          return () => {
            if (extWindow) {
              extWindow.hide()
            }
          }
        })
        /* eslint-disable no-console */
        .catch((err) => console.log(err))
    }
  }

  if (!containerElement) {
    return null
  }

  if (!externalWindow) {
    return null
  }

  // Render this component's children into the root element of the popout window
  // return ReactDOM.createPortal(props.children, containerElement)

  return ReactDOM.createPortal(
    <UserEditorModal closeFunc={closeFunc} userId={selectedUser} wrapModal />,
    containerElement
  )
}

export default UserEditorPopout
