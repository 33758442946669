import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import styles from '../DepthOfMarket/depthPopout.module.scss'
import {
  getDepthSecurities,
  getPopoutSecurityIds
} from '../../store/depthOfMarket/selectors'
import { fetchSecuritiesByIds } from '../../store/securities/actions'
import { SecurityStaticData } from '../../store/securities/reducer'
import { getIsAdmin } from '../../store/webSettings/selectors'
import PopoutDepthOfMarket from '../DepthOfMarket/PopoutDepthOfMarket'
import DepthPopout from './Depth'

const getKey = (
  security: SecurityStaticData,
  index: number,
  all: SecurityStaticData[]
) => {
  const prevSecurities = all.slice(0, index)
  const matchingSecurities = prevSecurities.filter((s) => s.id === security.id)
  return matchingSecurities.length
}
const DepthOpener: FC = () => {
  const { fin } = useOpenFin()
  const isAdmin = useSelector(getIsAdmin)
  const securityIds = useSelector(getPopoutSecurityIds)
  const securities = useSelector(getDepthSecurities)

  const dispatch = useDispatch()

  useEffect(() => {
    // todo: check to see if we already have static/order data
    if (securityIds.length) dispatch(fetchSecuritiesByIds(securityIds))
  }, [securityIds])

  if (!securities.length) return <></>

  return (
    <div className={fin ? '' : styles.domContainer}>
      {securities.map((security, i, all) =>
        // TS doesn't believe we have this even though we filter in the selector
        security?.id ? (
          fin ? (
            <DepthPopout
              key={`${security.id}-${getKey(
                security,
                i,
                all as unknown as SecurityStaticData[]
              )}`}
              showCloseAll={all.length > 1}
              securityId={security.id}
              isAdmin={isAdmin}
            />
          ) : (
            <PopoutDepthOfMarket
              security={security}
              showCloseAll={all.length > 1}
              gridIndex={0}
              isAdmin={isAdmin}
              key={`${security.id}-${getKey(
                security,
                i,
                all as unknown as SecurityStaticData[]
              )}`}
            />
          )
        ) : (
          <></>
        )
      )}
    </div>
  )
}

DepthOpener.displayName = 'DepthOpener'

export default DepthOpener
