import cx from 'classnames'
import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTotalSecondsBeforeExpiration } from '../../helpers/orders'
import { Activity } from '../../store/activity/types'
import { muteAlertFilter } from '../../store/alertFilter/actions'
import { getAlertFilter } from '../../store/alertFilter/selectors'
import { getAlert } from '../../store/alerts/selectors'
import { popoutSecurity } from '../../store/depthOfMarket/actions'

import { rejectOrder } from '../../store/lastLook/actions'
import { cancelOrder } from '../../store/order/actions'
import { getInitialOrder, getOrderById } from '../../store/order/selectors'
import {
  foregroundAggressorWindow,
  foregroundLastLookWindow
} from '../../store/windows/actions'
import { getServerTimeDelayMs } from '../../store/ws/selectors'
import TimerLeftComponent from '../Timer/Timer'
import AcceptButton from './AcceptButton'
import styles from './ActivityCard.module.scss'
import Badge from './Badge'

interface Props {
  activity: Activity
  handleTradeConfirmClick: (id: string) => void
}

const ActivityCard: FC<Props> = ({ activity, handleTradeConfirmClick }) => {
  const {
    cardColor,
    title,
    description,
    descriptionIsDimmed = false,
    badgeColor,
    badgeText,
    badgeInfo,
    date,
    hasLastLookActions,
    hasAggressorActions,
    hasMuteAction,
    isPending,
    type,
    id,
    alertType,
    formattedTitle,
    securityId,
    alertFilterId
  } = activity

  const dispatch = useDispatch()
  const serverTimeDelayMs = useSelector(getServerTimeDelayMs)
  const initialOrder = useSelector(getInitialOrder)
  const getAlertById = useSelector(getAlert)
  const getOrder = useSelector(getOrderById)

  const hasTradeConfirmAction = activity.type === 'trade'

  const alertFilterMuted =
    alertType && securityId
      ? useSelector(getAlertFilter)(alertFilterId!)?.muted
      : undefined

  const handleCancelClick = useCallback(() => {
    dispatch(cancelOrder(id))
  }, [])

  const handleRejectClick = useCallback(() => {
    dispatch(rejectOrder(id))
  }, [])

  const handleMuteClick = useCallback(() => {
    dispatch(muteAlertFilter(alertFilterId!, true))
  }, [activity])

  const handleUnmuteClick = useCallback(() => {
    dispatch(muteAlertFilter(alertFilterId!, false))
  }, [activity])

  const onTradeConfirmClick = useCallback(() => {
    // api is stripping the leading 0s - parseInt not working
    const tradeConfirmId = id.length === 6 ? id.substring(1) : id
    handleTradeConfirmClick(tradeConfirmId)
  }, [activity])

  const handlePendingTradeClick = () => {
    const action = hasAggressorActions
      ? foregroundAggressorWindow
      : foregroundLastLookWindow
    const orderId = hasAggressorActions ? initialOrder(id)?.id : id

    if (orderId) {
      dispatch(action(orderId))
    }
  }

  const handleTradeClick = () => {
    const order = getOrder(id)
    if (order) {
      dispatch(popoutSecurity(order.securityId))
    } else if (securityId) {
      dispatch(popoutSecurity(securityId))
    }
  }

  const handleAlertClick = (e: React.MouseEvent<HTMLElement>) => {
    const input = e.target as HTMLElement
    if (input.id === 'mute-btn') {
      return
    }
    const alert = getAlertById(
      alertType === 'tradingNow' ? Number(id) : String(id)
    )
    if (alert) {
      dispatch(popoutSecurity(alert.securityId))
    }
  }

  const handleActivityClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      switch (type) {
        case 'pendingTrade':
          return handlePendingTradeClick()
        case 'trade':
        case 'tradeAttempt':
          return handleTradeClick()
        case 'alert':
          return handleAlertClick(e)
      }
    },
    [type]
  )

  const activityCardWrapper = cx(
    styles.activityCard,
    styles[`border-${cardColor}`]
  )

  const { totalSeconds } = getTotalSecondsBeforeExpiration(
    activity.expiration,
    activity.submitTime,
    serverTimeDelayMs
  )

  return (
    <div className={activityCardWrapper}>
      {(isPending || hasMuteAction || hasTradeConfirmAction) && (
        <div className={styles.actionsWrapper}>
          <div className={styles.timerWrapper}>
            {activity.expiration && (
              <TimerLeftComponent
                valueSize="small"
                timerInit={totalSeconds}
                valueColor="var(--activeBackgroundColor)"
                totalSecondsBeforeExpiration={{
                  expiration: activity.expiration,
                  submitTime: activity.submitTime,
                  serverTimeDelayMs
                }}
              />
            )}
          </div>

          {isPending && hasAggressorActions && (
            <>
              <button
                data-testid="pending-cancel"
                className={cx(styles.actionButton, styles.cancel)}
                onClick={handleCancelClick}
              >
                Cancel Attempt
              </button>
            </>
          )}

          {isPending && hasLastLookActions && (
            <>
              <AcceptButton id={id} />
              <button
                data-testid="pending-reject"
                className={cx(styles.actionButton, styles.reject)}
                onClick={handleRejectClick}
              >
                Reject
              </button>
            </>
          )}

          {hasMuteAction && (
            <>
              <button
                id="depth-btn"
                className={styles.actionButton}
                onClick={handleActivityClick}
              >
                Show Depth
              </button>
              <button
                id="mute-btn"
                className={styles.actionButton}
                onClick={alertFilterMuted ? handleUnmuteClick : handleMuteClick}
              >
                {alertFilterMuted ? 'Unmute Rule' : 'Mute Rule'}
              </button>
            </>
          )}

          {hasTradeConfirmAction && (
            <button
              id="mute-btn"
              className={cx(styles.actionButton, styles.mute)}
              onClick={onTradeConfirmClick}
            >
              View Details
            </button>
          )}
        </div>
      )}

      <div onClick={handleActivityClick}>
        <span className={styles.time}>{date.toLocaleTimeString()}</span>

        <h4 className={styles.title}>
          {formattedTitle ? formattedTitle : title}
        </h4>
        <p
          className={cx(
            styles.description,
            descriptionIsDimmed && styles.descriptionDimmed
          )}
        >
          {description}
        </p>
        {badgeColor && (
          <div className={styles.badgeContainer}>
            <span className={styles.badge}>
              <Badge color={badgeColor}>{badgeText}</Badge>
            </span>
            {badgeInfo && <span className={styles.badgeInfo}>{badgeInfo}</span>}
          </div>
        )}
      </div>
    </div>
  )
}

export default ActivityCard
