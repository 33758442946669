import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClose,
  faWindowMinimize
} from '@awesome.me/kit-5de77b2c02/icons/duotone/solid'
import { useOpenFin } from '../../app/openFinContext'
import styles from './ComponentHeader.module.scss'

export const modalHeaderClose = (closeFunc: () => void) => {
  return (
    <div className={styles.icon} onClick={closeFunc} title="Close">
      <FontAwesomeIcon icon={faClose} />
    </div>
  )
}

export const finHeaderButtons = (title: string) => {
  const { fin, manageWindows } = useOpenFin()
  if (!fin) return <></>
  return (
    <>
      <div
        className={styles.icon}
        onClick={() => manageWindows(title, 'minimize')}
        title="Minimize"
      >
        <FontAwesomeIcon icon={faWindowMinimize} />
      </div>
      <div
        className={styles.icon}
        onClick={() => manageWindows(title, 'close')}
        title="Close"
      >
        <FontAwesomeIcon icon={faClose} />
      </div>
    </>
  )
}
