import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  adminTradeBlotterFetch,
  unsubscribeFromBlotter
} from '../../../store/admin/adminTradeBlotter/actions'
import { getCurrentTheme } from '../../../store/userPreferences/selectors'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import { finHeaderButtons } from '../../ComponentHeader/helpers'
import gridStyles from '../../Grid/grid.module.scss'
import TradeBlotterModal from './AdminTradeBlotterModal'
import TradeBlotterGrid from './Grid/TradeBlotterGrid'

const TradeBlotter: FC = () => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector(getCurrentTheme)
  const [selectedRow, setSelectedRow] = useState<string>('')
  const [displayModal, toggleModal] = useState(false)

  useEffect(() => {
    dispatch(adminTradeBlotterFetch())
    return () => {
      dispatch(unsubscribeFromBlotter())
    }
  }, [])

  const gridDoubleClick = (row: any) => {
    handleShowModal(row.data.ticketId)
  }

  const handleShowModal = useCallback(
    (row: string) => {
      setSelectedRow(row)
      toggleModal(!displayModal)
    },
    [displayModal, selectedRow]
  )

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader
        title="Trade Blotter"
        headerButtons={finHeaderButtons('TradeBlotter')}
      />
      <div className={cx(gridStyles.gridDimensions, theme, 'trade-blotter')}>
        <TradeBlotterGrid onDoubleClick={gridDoubleClick} />
      </div>
      {displayModal && (
        <TradeBlotterModal
          closeFunc={() => toggleModal(false)}
          paramId={selectedRow}
        />
      )}
    </div>
  )
}

export default TradeBlotter
