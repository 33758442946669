import cx from 'classnames'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { faAngleDown } from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useClickAway } from 'react-use'
import { defaultFilter } from '../../store/filter/types'
import {
  createGrid,
  saveAdvancedFilter,
  setIsMine,
  setMyBook,
  setMyFirm,
  setSizeFilter,
  setUseAdvancedFilter,
  setWatchListId
} from '../../store/securities/actions'
import { getGridCount, getWatchlistId } from '../../store/securities/selectors'
import { toggleDropdownState } from '../../store/upload/actions'
import {
  deleteWatchlist,
  hideWatchlist,
  updateWatchlistName
} from '../../store/watchList/actions'
import { WatchList } from '../../store/watchList/types'
import { getMaxGrids } from '../../store/webSettings/selectors'
import CreateNewWatchList from '../Upload/CreateNewDropDownListItem'
import styles from './dropDown.module.scss'
import ListItem from './ListItem'

interface Props {
  gridIndex: number
  watchLists: WatchList[]
  setActive: (b: boolean) => void
  className: string
}

const allLabel = 'All'
const mineLabel = 'Mine'

const DropDownWatchlistMenu: FC<Props> = ({
  gridIndex,
  watchLists,
  setActive,
  className
}) => {
  const dispatch = useDispatch()
  const watchlistIdSelected = useSelector(getWatchlistId)(gridIndex)
  const gridCount = useSelector(getGridCount)()
  const maxGrids = useSelector(getMaxGrids)
  const [watchlistLabel, setWatchlistLabel] = useState<string | undefined>(
    allLabel
  )
  const [displayMenu, setDisplayMenu] = useState(false)
  const [selectedWatchlists, setSelectedWatchlists] = useState<
    Array<{
      id: number | undefined
      owner: boolean
    }>
  >([])

  const ref = useRef<HTMLDivElement>(null)
  useClickAway(ref, () => setDisplayMenu(false))

  useEffect(() => {
    setWatchlistLabel(
      watchlistIdSelected === undefined
        ? allLabel
        : watchlistIdSelected === 0
        ? mineLabel
        : watchLists.find((watch) => watch.id === watchlistIdSelected)?.name
    )
    if (watchLists.length > 0) {
      // putting ig/hy MARKET watchlists at the beginning of the list
      const igWatchlist = watchLists.findIndex(
        (w) => w.name === 'IG Most Active'
      )
      const hyWatchlist = watchLists.findIndex(
        (w) => w.name === 'HY Most Active'
      )
      watchLists.unshift(watchLists.splice(igWatchlist, 1)[0])
      watchLists.splice(1, 0, watchLists.splice(hyWatchlist, 1)[0])
    }
  }, [watchlistIdSelected, watchLists])

  const resetFilters = () => {
    dispatch(saveAdvancedFilter(gridIndex, defaultFilter))
    dispatch(setMyFirm(gridIndex, false))
    dispatch(setMyBook(gridIndex, false))
    dispatch(setSizeFilter(gridIndex, false, 200))
    dispatch(setUseAdvancedFilter(gridIndex, false))
  }

  useEffect(() => {
    if (watchlistIdSelected === undefined || watchlistIdSelected === 0) {
      resetFilters()
    }

    if (watchlistIdSelected) {
      watchLists.map((watchlist) => {
        if (watchlist.id === watchlistIdSelected) {
          if (watchlist.useAdvancedFilter) {
            dispatch(saveAdvancedFilter(gridIndex, watchlist.filter))
            dispatch(setMyFirm(gridIndex, watchlist.myFirmChecked))
            dispatch(setMyBook(gridIndex, false))
            dispatch(
              setSizeFilter(gridIndex, watchlist.useSizeChecked, watchlist.size)
            )
            dispatch(
              setUseAdvancedFilter(gridIndex, watchlist.useAdvancedFilter)
            )
          } else {
            resetFilters()
          }
        }
      })
    }
    setActive(false)
  }, [watchlistIdSelected])

  const showDropdownMenu = useCallback(() => {
    setDisplayMenu(true)
    dispatch(toggleDropdownState(gridIndex, 'closed'))
  }, [displayMenu])

  const onSelectionWatchList = useCallback((watchListId: number) => {
    dispatch(setWatchListId(gridIndex, watchListId))
    if (watchListId === 0) {
      dispatch(setIsMine(gridIndex, true))
    }
    setDisplayMenu(false)
  }, [])

  const watchlistsToDelete = (watchlistId: any, isOwner: boolean) => {
    !selectedWatchlists.some((watchlist) => watchlist.id === watchlistId)
      ? setSelectedWatchlists((watchlists) => [
          ...watchlists,
          { id: watchlistId, owner: isOwner }
        ])
      : setSelectedWatchlists((watchlists) =>
          watchlists.filter((watchlist) => watchlist.id !== watchlistId)
        )
  }

  const handleDeleteWatchlists = () => {
    selectedWatchlists.forEach((watchlistInfo) => {
      handleDeleteWatchlist(watchlistInfo)
    })
    setSelectedWatchlists([])
  }

  // TODO fix the TS types on this
  const handleDeleteWatchlist = useCallback((watchListInfo) => {
    watchListInfo.owner || watchListInfo.isOwner
      ? dispatch(deleteWatchlist(watchListInfo.id))
      : dispatch(hideWatchlist(watchListInfo.id))

    setDisplayMenu(false)
  }, [])

  const popoutWatchlist = useCallback(
    (watchlistId: WatchList['id']) => {
      if (gridCount < maxGrids) {
        dispatch(createGrid(watchlistId, gridIndex, watchlistId === 0))
      }
      setDisplayMenu(false)
    },
    [gridCount, maxGrids]
  )

  const editWatchlistName = useCallback(
    (watchlistId: WatchList['id'], newName) => {
      dispatch(updateWatchlistName(watchlistId!, newName))
    },
    [updateWatchlistName]
  )

  const allWatchlist: WatchList = {
    id: undefined,
    name: allLabel,
    canEdit: false,
    isOwner: false,
    permission: 'myFirm',
    book: '',
    transactionId: 0,
    filter: defaultFilter,
    myFirmChecked: false,
    useSizeChecked: false,
    size: 200,
    useAdvancedFilter: false,
    symbolsAndCusips: [],
    excludedCusips: [],
    ownerUserName: ''
  }

  const mineWatchlist: WatchList = {
    id: 0,
    name: mineLabel,
    canEdit: false,
    isOwner: false,
    permission: 'myFirm',
    book: '',
    transactionId: 0,
    filter: defaultFilter,
    myFirmChecked: false,
    useSizeChecked: false,
    size: 200,
    useAdvancedFilter: false,
    symbolsAndCusips: [],
    excludedCusips: [],
    ownerUserName: ''
  }

  return (
    <div ref={ref} className={styles.dropdown}>
      <div className={styles.dropdownLabelAndButton}>
        <div
          className={cx(styles.btnLabel, styles.btnDropDown)}
          data-testid="watchlistDropdown"
          onClick={showDropdownMenu}
        >
          {watchlistLabel} <FontAwesomeIcon icon={faAngleDown} />
        </div>{' '}
      </div>
      {displayMenu && (
        <div className={styles.contentDropDownMenu}>
          <div className={styles.watchlists}>
            <CreateNewWatchList
              gridIndex={gridIndex}
              closeMenu={() => setDisplayMenu(false)}
            />

            {watchLists &&
              [allWatchlist, mineWatchlist, ...watchLists].map((wl) => (
                <ListItem
                  watchList={wl}
                  selected={watchlistIdSelected === wl.id}
                  onSelectItem={onSelectionWatchList}
                  key={`watchList-${wl.id}`}
                  deleteWatchlist={handleDeleteWatchlist}
                  popoutWatchlist={popoutWatchlist}
                  editWatchlistName={editWatchlistName}
                  gridIndex={gridIndex}
                  watchlistsToDelete={watchlistsToDelete}
                />
              ))}
          </div>
          {selectedWatchlists.length !== 0 && (
            <div className={className}>
              <div className={styles.message}>
                <span className={styles.number}>
                  {selectedWatchlists.length}
                </span>{' '}
                selected
              </div>
              <div>
                <button onClick={handleDeleteWatchlists}>
                  Remove Watchlists
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DropDownWatchlistMenu
