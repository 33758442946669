import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import { fetchAlertFilters } from '../../store/alertFilter/actions'
import { getAlertFilters } from '../../store/alertFilter/selectors'
import ComponentHeader from '../ComponentHeader/ComponentHeader'
import Modal from '../GenericModal/Modal'
import AlertFilterEdit from './AlertFilterEdit'
import AlertFilterList from './AlertFilterList'
import { finHeaderButtons } from '../ComponentHeader/helpers'
import styles from './AlertFilter.module.scss'

export interface Props {
  closeFunc: () => void
}
const AlertFilterEditor: FC<Props> = ({ closeFunc }) => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAlertFilters())
  }, [])
  const alertFilters = useSelector(getAlertFilters)
  const selectedId = alertFilters.length > 0 ? alertFilters[0].id : 0
  const [selectedAlertFilterId, setSelectedAlertFilterId] = useState(selectedId)

  const contentModal = (
    <>
      <ComponentHeader
        title={'Alert Manager'}
        headerButtons={finHeaderButtons('AlertManager')}
      />
      <div className={styles.alertWrapper}>
        <AlertFilterList
          filters={alertFilters}
          selectAlertFilterId={setSelectedAlertFilterId}
          selectedAlertFilterId={selectedAlertFilterId}
        />
        <AlertFilterEdit
          filterId={selectedAlertFilterId}
          closeFunc={closeFunc}
          key={selectedAlertFilterId}
        />
      </div>
    </>
  )

  return <>{fin ? contentModal : <Modal>{contentModal}</Modal>}</>
}

export default AlertFilterEditor
