import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AsyncSelect from 'react-select/async'
import { useOpenFin } from '../../app/openFinContext'
import UserEditorPopout from '../../containers/Openfin/Admin/UserEditorPopout'
import { fetchOperatorOrders } from '../../store/order/actions'
import { setSelectedUser, userFetch } from '../../store/users/actions'
import { getSelectedUser, getUsers } from '../../store/users/selectors'
import { User } from '../../store/users/types'
import UserEditorModal from './UserEditorModal'
import styles from './UserSelector.module.scss'
import { getSelectStyles } from '../../helpers/formatting'

const UserSelector: FC = () => {
  const { fin } = useOpenFin()
  const dispatch = useDispatch()
  const allUsers = useSelector(getUsers)
  const selectedUser = useSelector(getSelectedUser)

  const [userOptions, setUserOptions] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [initialized, setInitialized] = useState(false)

  const [usersByUserId, setUsersByUserId] = useState<Record<
    number,
    User
  > | null>({})
  const [usersByCustId, setUsersByCustId] = useState<Record<
    number,
    User[]
  > | null>({})

  const [displayModal, setDisplayModal] = useState(false)

  const filterColors = (inputValue: string) => {
    const stringLower = inputValue.toLocaleLowerCase()
    if (stringLower.startsWith('uid:')) {
      const uidStr = stringLower.split(':')[1]
      const uidNum = Number(uidStr)
      if (usersByUserId?.hasOwnProperty(uidNum)) {
        const usr = usersByUserId[uidNum]
        return [{ label: usr.userName, value: usr.id.toString() }]
      }
    } else if (stringLower.startsWith('cid:')) {
      const uidStr = stringLower.split(':')[1]
      const uidNum = Number(uidStr)
      if (usersByCustId?.hasOwnProperty(uidNum)) {
        const usrs = usersByCustId[uidNum]
        return usrs.map((usr) => ({
          label: usr.userName,
          value: usr.id.toString()
        }))
      }
    } else {
      return userOptions.filter(
        (opt) => opt.label.toLocaleLowerCase().indexOf(stringLower) > -1
      )
    }
  }

  useEffect(() => {
    dispatch(fetchOperatorOrders())
    dispatch(userFetch())
  }, [])

  useEffect(() => {
    const newUsersByUserId: Record<number, User> = {}
    const newUsersByCustId: Record<number, User[]> = {}
    if (allUsers) {
      for (const usr of allUsers) {
        newUsersByUserId[usr.id] = usr
        if (!newUsersByCustId[usr.custId]) {
          newUsersByCustId[usr.custId] = []
        }
        newUsersByCustId[usr.custId].push(usr)
      }
      setUsersByUserId(newUsersByUserId)
      setUsersByCustId(newUsersByCustId)
      setUserOptions(
        allUsers.map((usr) => ({
          label: `${usr.custName}: ${usr.userName}`,
          value: usr.id.toString()
        }))
      )
      setInitialized(true)
    }
  }, [allUsers])
  const loadOptions = (inputValue: string, callback: any) => {
    setTimeout(() => {
      callback(filterColors(inputValue))
    }, 1000)
  }

  const handleChange = (event: any) => {
    event
      ? dispatch(setSelectedUser(Number(event.value)))
      : dispatch(setSelectedUser(0))
  }

  const handleShowModal = useCallback(() => {
    setDisplayModal(!displayModal)
  }, [displayModal])

  return (
    <div className={styles.wrapper}>
      {initialized && (
        <React.Fragment>
          <AsyncSelect
            styles={getSelectStyles}
            className={cx(
              styles.UserSelect,
              styles.inline,
              fin && styles.finSelect
            )}
            loadOptions={loadOptions}
            isClearable
            defaultOptions
            onChange={handleChange}
            placeholder="Act on Behalf of User..."
          />
          <button
            className={cx(styles.inline, selectedUser === 0 && styles.hidden)}
            onClick={handleShowModal}
          >
            Edit
          </button>

          {displayModal &&
            (fin ? (
              <UserEditorPopout closeFunc={() => setDisplayModal(false)} />
            ) : (
              <UserEditorModal
                closeFunc={() => setDisplayModal(false)}
                userId={selectedUser}
                wrapModal={false}
              />
            ))}
        </React.Fragment>
      )}
    </div>
  )
}

export default UserSelector
